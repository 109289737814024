<template>
  <div id="app">
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
    >
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'A-App',
  components: {
  }
}
</script>

<style>

.md-menu-content.md-select-menu {
  width: auto;
  /*max-width: 400px !important;*/
}
.md-title {
  font-size: 20px !important;
}

</style>
