import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/admin',
    name: 'admin',
    component: () => import('../views/A-Admin.vue')
  },
  {
    path: '/gdc',
    name: 'gcd',
    component: () => import('../views/G-GDC.vue')
  },
  {
    path: '/prt',
    name: 'prt',
    component: () => import('../views/Mtto-PRT.vue')
  },
  {
    path: '/prt/:prefill',
    name: 'prt',
    component: () => import('../views/Mtto-PRT.vue')
  },
  {
    path: '/gdc/modif/:id',
    name: 'modif',
    props: true,
    component: () => import('../views/M-Modif.vue')
  },
  {
    path: '*',
    name: 'Home',
    //component: Home
    component: () => import(/* webpackChunkName: "about" */ '../views/H-Home.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
